
import React from 'react';
import {css} from '@emotion/react';
import {Wrapper, FlexBox, FlexContainerRow, blogheader, mq} from "../styles/globalstyles";
import { BlogAutor } from '../components/blog-autorlist';
import BlogPostSEO from '../SEO/blogpost-seo';
import { SmallOverline } from '../components/overline';
import { FormWhitepaper } from '../components/forms/form-whitepaper';

const BlogPost = ({pageContext}) => {
    let d = new Date(pageContext.datum)

     return(    
    <Wrapper>
        <BlogPostSEO
            title={pageContext.titel}
            description={pageContext.description}
            dateCreated={pageContext.created}
            datePublished={pageContext.datum}
            dateModified={pageContext.updated}
            authorName={pageContext.blogAutoren && pageContext.blogAutoren?.length > 0 ? pageContext.blogAutoren[0].vorname + " " + pageContext.blogAutoren[0].nachname : 'Proceed GmbH'}
            siteUrl={ "https://proceed.gmbh/wissenswertes/" + pageContext.slug}
            imageUrl={pageContext.header.url}
            />
            <div css={blogheader}>
        
             <img  height="auto" width="100%" align="top" src={pageContext.header.url} />
            </div>
            <FlexContainerRow css={css`align-items: flex-start !important; gap: 2.4rem;`}>
                <FlexBox direction="column" justify="flex-start" css={mq({marginBottom: "2em", width: ["100%", "100%", "100%", "40%", "40%"]})}>
                    <SmallOverline><span>{d.getDate() +"."+  (d.getMonth() +1) +"."+ d.getFullYear()}</span></SmallOverline>
                    <h1>{pageContext.titel}</h1>
                    
                    <h5> {pageContext.untertitel}</h5>
                     <div css={{display: "flex", flexDirection: "row", justifyContent: "space-between", alingItems: "center", flexWrap: "wrap"}}  > 
                        {pageContext.blogAutoren.map((value, index) => {
                                return <BlogAutor autor={value} key={index} />
                            })
                        }
                    </div>
                    {pageContext.bestellformular && <FormWhitepaper />}
                </FlexBox>
                <FlexBox direction="column" css={mq({width: ["100%", "100%", "100%", "60%", "60%"] })} >
                    <div className="blogtext" dangerouslySetInnerHTML={{__html: pageContext.text.html}} />
                </FlexBox>
            </FlexContainerRow>
        </Wrapper>
    );
};


export default BlogPost;